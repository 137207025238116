import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { SideBarService } from '../../services/sidebar.service';
import { Router } from '@angular/router';
import { RoleGuardService } from '../../services/role-guard.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  navItems: any;
  sidebarMinimized = true;
  element: HTMLElement;
  hidden = false;
  currentUser = {
    name: '',
    team: ''
  };
  private readonly changes: MutationObserver;

  constructor(
    private readonly roleGuardService: RoleGuardService,
    private readonly sideBarService: SideBarService,
    private readonly router: Router,
    @Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver(mutations => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(this.element as Element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  ngOnInit(): void {
    this.setUserInfo();
    this.navItems = this.sideBarService.SideBarItems;
    
  }
  
  ngOnDestroy(): void {
    this.changes.disconnect();
  }
  
  setUserInfo(): void {
    const user = this.roleGuardService.decodedToken.user;
    console.log(user);
    if (user) {
      this.currentUser.name = user.name;
    }
  }

  logOut(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    this.router.navigate(['/login']).catch();
    window.location.reload();
  }

  clearCache(): void {
    localStorage.clear();
    this.router.navigate(['/login']);
    window.location.reload();
  }


}
