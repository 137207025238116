import { ComponentFactoryResolver, ComponentRef, ElementRef, OnDestroy, OnInit, QueryList, Renderer2, ViewContainerRef } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { BroadcastService } from '../../services/broadcast.service';
var DatatableComponent = /** @class */ (function () {
    function DatatableComponent(viewRef, _renderer, compFactory, broadcast) {
        this.viewRef = viewRef;
        this._renderer = _renderer;
        this.compFactory = compFactory;
        this.broadcast = broadcast;
        this.dtTrigger = new Subject();
        this.dtOptions = {};
        this.processing = false;
        this.filters = {};
        this.items = [];
        this.openRows = [];
    }
    DatatableComponent.prototype.ngOnInit = function () {
        var _this = this;
        var dtOpts = {
            pagingType: 'full_numbers',
            pageLength: 10,
            serverSide: true,
            processing: true,
            searchDelay: 3000,
            responsive: true,
            language: {
                url: 'https://cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
            },
            initComplete: function () {
                // tslint:disable-next-line: no-invalid-this
                $(this.api().table().container()).find('input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
            },
            ajax: function (dataTablesParameters, callback) {
                var baseService = _this.options.config.base;
                var baseFunction = _this.options.config.api;
                if (dataTablesParameters) {
                    _this.processing = true;
                    _this.tableService$ = baseService["" + baseFunction](dataTablesParameters, _this.filters)
                        .subscribe(function (resp) {
                        _this.items = resp.data;
                        _this.superAdmin = resp.superAdmin;
                        _this.processing = false;
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                    });
                }
            },
            columns: (function () {
                var cols = [];
                _this.options.columns.forEach(function (col) {
                    var columnParam = {
                        data: "" + col.field,
                        orderable: true
                    };
                    if (col.field === '') {
                        columnParam.data = null;
                        columnParam.orderable = false;
                    }
                    if (col.field.indexOf('.') > -1) {
                        columnParam.data = null;
                    }
                    if (col.orderable === false) {
                        columnParam.orderable = false;
                    }
                    if (col.width) {
                        // tslint:disable-next-line: no-string-literal
                        columnParam['width'] = col.width;
                    }
                    cols.push(columnParam);
                });
                return cols;
            })()
        };
        if ('order' in this.options.config) {
            Object.assign(dtOpts, { order: this.options.config.order });
        }
        if ('ordering' in this.options.config) {
            Object.assign(dtOpts, { ordering: this.options.config.ordering });
        }
        if ('searching' in this.options.config) {
            Object.assign(dtOpts, { searching: this.options.config.searching });
        }
        if ('paging' in this.options.config) {
            Object.assign(dtOpts, { paging: this.options.config.paging });
        }
        if ('params' in this.options.config) {
            Object.assign(this.filters, this.options.config.params);
        }
        this.dtOptions = dtOpts;
        this.dtService = this.broadcast.events
            .subscribe(function (event) {
            switch (event.name) {
                case 'datatable-filter':
                    _this.filterChange(event.data);
                    break;
                // case 'datatable-show-childTable': this.expandRow(event.data); break;
            }
        });
    };
    /**
     * Experimental
     * @param rowData asdasdasd
     */
    DatatableComponent.prototype.expandRow = function (rowData) {
        var _this = this;
        this.datatableElement.dtInstance.then(function (dtInstance) {
            var index = 0;
            for (var i = 0; i < _this.trRef.length; i++) {
                if (_this.trRef.toArray()[i].nativeElement === rowData.ref) {
                    index = i;
                }
            }
            var row = dtInstance.row(rowData.ref);
            row.push([index]);
            if (row.child.isShown()) {
                row.child.hide();
                _this._renderer.removeClass(rowData.ref, 'shown');
                _this.openRows.splice(_this.openRows.indexOf(index), 1);
            }
            else {
                _this.openRows.push(index);
                var contRef_1;
                setTimeout(function () {
                    contRef_1 = _this.trChild.find(function (child) {
                        var elem = child.element;
                        var id = parseInt(elem.nativeElement.id.split('child-')[1], 10);
                        return id === index;
                    });
                    if (contRef_1) {
                        row.child('<p>holi</p>').show();
                        _this._renderer.addClass(rowData.ref, 'shown');
                        var childRow = void 0;
                        var factory = _this.compFactory.resolveComponentFactory(DatatableComponent);
                        childRow = contRef_1.createComponent(factory);
                        childRow.instance.options = _this.options.childTable;
                    }
                });
            }
        });
        // this.broadcast.events.complete();
    };
    DatatableComponent.prototype.rowClick = function (data) {
        this.broadcast.fire({
            name: this.options.config.type + "-row-click",
            data: data
        });
    };
    DatatableComponent.prototype.ngOnDestroy = function () {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
        if (this.tableService$) {
            this.tableService$.unsubscribe();
        }
        if (this.dtService) {
            this.dtService.unsubscribe();
        }
    };
    DatatableComponent.prototype.onClickAction = function (value) {
        this.ticketAction = value;
    };
    DatatableComponent.prototype.filterChange = function (params) {
        var _this = this;
        var _a;
        if (params.multi) {
            Object.keys(params).forEach(function (key) {
                var _a;
                if (key !== 'multi') {
                    Object.assign(_this.filters, (_a = {}, _a[key] = params[key], _a));
                }
            });
        }
        else {
            Object.assign(this.filters, (_a = {}, _a[params.type] = params.value, _a));
        }
        this.datatableElement.dtInstance.then(function (dtInstance) {
            dtInstance.draw(false);
        }).catch();
    };
    DatatableComponent.prototype.isVisible = function (conditionallity) {
        // tslint:disable-next-line: no-eval
        return eval(conditionallity);
    };
    return DatatableComponent;
}());
export { DatatableComponent };
