<app-header [navbarBrandRouterLink]="['/admin']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/aguagente-logo.png', width: 114, height: 50, alt: 'Aguagente Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo-aguagente-default.png', width: 30, height: 30, alt: 'Aguagente Logo'}"
  [sidebarToggler]="false" [mobileAsideMenuToggler]="false" [asideMenuToggler]="false">

  <div class="row text-center">
    <div class="col-sm-8 align-self-center" *ngIf="currentUser.name">{{currentUser.name}}</div>
    <div class="col-sm-2 align-self-center">
      <button type="button" class="btn btn-link" (click)="logOut()" title="logout"><i
          class="fa fa-sign-out"></i></button>
    </div>
    <div class="col-sm-2">
      <button type="button" class="btn btn-link" (click)="clearCache()" title="clearCache">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</app-header>

<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main">
    <cui-breadcrumb>
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>

</div>