<div class="modal-body" *ngIf="client">
    <div class="container-fluid">
        <div class="row">

            <div class="col-md-4">
                <h5 class="font-italic">Datos del cliente</h5>
                <br>
                <app-client-contract-data [clientData]="client"></app-client-contract-data>
                <br>
                <h5 class="font-italic">Referido por</h5>
                <br>
                <app-client-contract-data [clientData]="refeer"></app-client-contract-data>

                <ngb-tabset [destroyOnHide]="false">
                    <ngb-tab title="Pago inicial">
                        <ng-template ngbTabContent>
                            <h5 class="font-italic">Promoción</h5>
                            <br>

                            <dl class="row">
                                <dt class="col-sm-3">Dias de prueba</dt>
                                <dd class="col-sm-9"> {{signed_in_group.trial_days}} días X
                                    {{signed_in_group.trial_days_price | currency}}</dd>
                                <dt class="col-sm-3">Nombre del grupo</dt>
                                <dd class="col-sm-9">{{signed_in_group.name}}</dd>
                                <dt class="col-sm-3">Mensualidad regular</dt>
                                <dd class="col-sm-9">{{contractData.monthly_fee | currency}}</dd>
                            </dl>
                            <h5 class="font-italic">Pago inicial</h5>
                            <br>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Categoría</th>
                                        <th scope="col">Elemento</th>
                                        <th scope="col">Precio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Contrato</td>
                                        <td>Deposito</td>
                                        <!-- <td>Reviar si el deposito es del grupo o del cliente</td> -->
                                        <td>{{contractData.deposit | currency}}</td>
                                    </tr>

                                    <tr *ngIf="!checkIfMaterial()">
                                        <td>Contrato</td>
                                        <td>Instalación</td>
                                        <td>{{contractData.installation_fee | currency}}</td>
                                    </tr>

                                    <tr>
                                        <td>Contrato</td>
                                        <td>1° cargo</td>
                                        <td>{{contractData.first_charge | currency}}</td>
                                    </tr>

                                    <tr *ngFor="let extra of data.contract.extras">
                                        <td>{{extra.category_name}}</td>
                                        <td>{{extra.element_name}}</td>
                                        <td>
                                            <span [ngSwitch]="extra.element_name">
                                                <p *ngSwitchCase="'Acero inoxidable'">
                                                    {{(contractData.installation_fee) | currency}}
                                                </p>
                                                <p *ngSwitchDefault>
                                                    {{extra.price | currency}}
                                                </p>
                                            </span>
                                        </td>
                                    </tr>

                                    <tr *ngIf="data.social_responsability == 1">
                                        <td colspan="2">Responsabilidad Social</td>
                                        <td>{{contractData.sr | currency}}</td>
                                    </tr>

                                    <tr *ngIf="data.charge_fee > 0">
                                        <td>Comisiones bancarias</td>
                                        <td>{{contractData.bank_fee | currency}}</td>
                                    </tr>

                                    <tr *ngIf="data.id_coupon > 0">
                                        <td>Cupón</td>
                                        <td>{{data.coupon.token}}</td>
                                        <td>-{{data.coupon.reward | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table invoice-total">
                                <tbody>
                                    <tr>
                                        <td><strong>TOTAL: </strong></td>
                                        <td>{{contractData.total | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Información de pago actual">
                        <ng-template ngbTabContent>
                            <dl class="row">
                                <dt class="col-sm-6">Mesualidad regular</dt>
                                <dd class="col-sm-6">{{regular_monthly_fee | currency}}</dd>
                                <dt *ngIf="data.regular_start_date" class="col-sm-6">Inicio de servicio regular</dt>
                                <dd *ngIf="data.regular_start_date" class="col-sm-6">{{data.regular_start_date | date}}
                                </dd>
                                <dt *ngIf="data.premium_start_date" class="col-sm-6">Inicio de servicio premium</dt>
                                <dd *ngIf="data.premium_start_date" class="col-sm-6">{{data.premium_start_date | date}}
                                </dd>
                            </dl>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>

            <div class="col-md-8 text-center">
                <ngb-tabset [destroyOnHide]="false">
                    <ngb-tab title="Documentos" *ngIf="documents.length > 0">
                        <ng-template ngbTabContent>
                            <ngb-carousel [interval]="false">
                                <ng-template ngbSlide *ngFor="let document of documents">
                                    <div class="picsum-img-wrapper">
                                        <img src="{{baseUrl}}/documents/contracts/{{document}}" class="contract-image"
                                            alt="Client contract documents">
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab title="Imagenes" *ngIf="photos.length > 0">
                        <ng-template ngbTabContent>
                            <ngb-carousel [interval]="false">
                                <ng-template ngbSlide *ngFor="let photo of photos">
                                    <div class="picsum-img-wrapper">
                                        <img src="{{baseUrl}}/documents/contracts/{{photo}}" class="contract-image"
                                            alt="Client contract photos">
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab *ngIf="!hiddeChargeTab">
                        <ng-template ngbTabTitle>
                            <span [ngClass]="{
                                    'text-info': client.cards.length > 0,
                                    'text-danger': client.cards.length === 0
                                }">
                                <i class="fa fa-credit-card-alt fa-fw fa-lg"></i>
                                Datos de pago
                            </span>
                        </ng-template>

                        <ng-template ngbTabContent>
                            <div class="container-fluid bg-white p-3">
                                <app-datatable [options]="dataTableConfig"></app-datatable>
                            </div>
                            <div
                                *ngIf="client.coordinate === null || client.coordinate === 'null' || client.coordinate === ''">
                                <h4>Favor de añadir las coordenadas al usuario para poder efectuar el primer cargo
                                </h4>
                            </div>
                            <!-- devolver client.payday a !== -->
                            <div class="text-center"
                                *ngIf="client.coordinate !== null || client.coordinate !== 'null' || client.coordinate !== ''">
                                <button class="btn btn-lg btn-info" data-style="zoom-in" data-spinner-color="#3e495f"
                                    data-spiner-size="20" (click)="manualPayment()" [hidden]="hideButton()"
                                    [disabled]="client.pay_day !== null">
                                    <i class="fa fa-money fa-lg fa-fw"></i>Recolectar primer pago
                                </button>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>

            </div>

        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>